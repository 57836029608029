import { AppBar, Toolbar } from '@material-ui/core';
import { IconButton } from '@tecsinapse/ui-kit';
import { withStyles } from '@material-ui/core/styles';
import { PowerOff } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TunnelPlaceholder } from 'react-tunnels';
import { compose, withProps, withState } from 'recompose';
import styled, { ThemeProvider } from 'styled-components';
import { chatStore } from 'components/Chat/ChatStore';
import { geoLocationDefinida } from '../../core/geolocationUtils';
import { renderOfflineMutationUI } from '../../core/offline/offlineHelpers/offlineMutationHelpers';
import { GeolocalizacaoPermissionContainer } from '../Geolocation/GeolocationManager';
import { ClienteDuplicadoDialogContainer } from '../Client/clientesDuplicadosDialog/ClienteDuplicadoDialogContainer';
import { globalBackgroundColor } from './globalStyle';
import {
  isInStandaloneMode,
  isIos,
  useWindowSize,
} from '../utils/BrowserUtils';
import { IosAddSnack } from '../utils/IosSnack';
import { Loading } from '../utils/Loading';
import { withUsuarioLogado } from '../UsuarioLogado/withUsuarioLogado';
import { withStoreConsumer } from '../utils/StoreConsumer';
import { ConfirmarEntrarEmContatoModalProvider } from '../providers/InformarContato/ConfirmarEntrarEmContatoModalProvider';
import { Alert } from '../utils/Alert';
import { ClientCreateOptionsModalContainer } from '../opportunity/create/ClientCreateOptionsModalContainer';
import PushManagerContainer from '../PushNotification/PushManagerContainer';
import { Routes } from './Routes';
import { NotAllowed } from '../utils/NotAllowed';
import { LoadingSpinner } from '../utils/LoadingSpinner';
import { BottomBarNavigation } from '../utils/BottomBarNavigation';

const routesWithoutFooter = ['/checkInConfirmacao', '/checkInCRMConfirmacao'];

const isRouteWithoutFooter = location =>
  !!routesWithoutFooter.find(r =>
    location.pathname.toLowerCase().includes(r.toLowerCase())
  );

const enhance = compose(
  withRouter,
  withUsuarioLogado,
  withState('appState', 'setAppState', {
    alert: null,
  }),
  withState(
    'showInstallMessage',
    'setShowInstallMessage',
    isIos() && !isInStandaloneMode()
  ),
  connect(state => ({ ...state.offlineBadge })),
  withStoreConsumer,
  withStyles({
    backButton: {
      marginLeft: '-12px',
      marginRight: '20px',
    },
    flex: {
      flex: 1,
    },
    title: {
      textTransform: 'capitalize',
    },
  }),
  withProps(
    ({
      usuarioConsultorExterno,
      usuarioLogado: { dealersVendedorLogado: dealers = [] },
      exibeRoteirizacao,
    }) => ({
      exibeRotas:
        usuarioConsultorExterno &&
        exibeRoteirizacao &&
        dealers.filter(r => r.roteirizacao === true).length > 0,
      usuarioConsultorExterno,
    })
  )
);

export const App = enhance(
  ({
    location,
    hasPermission,
    client,
    show,
    usuarioLogado,
    exibeIndicadores,
    exibeRotas,
    usuarioConsultorExterno,
    showInstallMessage,
    setShowInstallMessage,
    registrarAcesso,
    versao,
    setRegistraAcesso,
  }) => {
    const { acessoRegistrado } = useSelector(state => state.registroAcesso);
    const [, height] = useWindowSize();
    const [rendered, setRendered] = useState(false);
    const theme = useTheme();
    const { state } = useContext(chatStore);

    useEffect(() => {
      if (navigator.onLine && !acessoRegistrado) {
        registrarAcesso({
          variables: {
            acessoRegistro: {
              versao,
              navegador: window.navigator.userAgent.toLowerCase(),
            },
          },
        });
        setRegistraAcesso(true);
      }
    }, [acessoRegistrado, registrarAcesso, setRegistraAcesso, versao]);

    const callback = exibir => {
      setRendered(!exibir);
    };

    useEffect(() => {
      geoLocationDefinida(callback);
    }, []);

    return (
      <ThemeProvider theme={theme}>
        <ConfirmarEntrarEmContatoModalProvider>
          <div
            className="app"
            style={{
              height,
            }}
          >
            <div className="app-header">
              <AppBar variant="outlined" position="static">
                <AppToolbar renderchat={state.chatAtivo.toString()}>
                  <TunnelPlaceholder id="toolbar-left-icon" />
                  <TunnelPlaceholder id="toolbar-logo" />
                  <TunnelPlaceholder id="app-center" />
                  <TunnelPlaceholder id="app-title" />
                  <TunnelPlaceholder id="toolbar-right-icon" multiple />

                  {!!show && (
                    <IconButton
                      onClick={() =>
                        renderOfflineMutationUI({ client, offlineButton: true })
                      }
                      color="inherit"
                    >
                      <PowerOff />
                    </IconButton>
                  )}
                </AppToolbar>
                <TunnelPlaceholder id="app-search-bar" />
                <TunnelPlaceholder id="app-bar-bottom" />
              </AppBar>
            </div>

            <div
              className="app-content"
              style={{
                backgroundColor: globalBackgroundColor,
              }}
            >
              {hasPermission ? (
                <>
                  <Alert />
                  <LoadingSpinner />
                  {/* FIXME Remover isso daqui e jogar em context */}
                  <ClientCreateOptionsModalContainer />
                  <ClienteDuplicadoDialogContainer />
                  <PushManagerContainer
                    subscribeUrl={process.env.REACT_APP_PUSH_SUBSCRIBE_ENDPOINT}
                    vapidPubKey={process.env.REACT_APP_PUSH_PUB_VAPID}
                  />
                  <GeolocalizacaoPermissionContainer
                    rendered={rendered}
                    setRendered={setRendered}
                  />
                  <Suspense fallback={<Loading key="loader2" />}>
                    <Routes usuarioLogado={usuarioLogado} />
                  </Suspense>
                </>
              ) : (
                <NotAllowed />
              )}
            </div>

            {!isRouteWithoutFooter(location) && (
              <div className="app-footer">
                <div className="app-footer-content">
                  <BottomBarNavigation
                    className="app-navigator"
                    exibeIndicadores={exibeIndicadores}
                    exibeRotas={exibeRotas}
                    usuarioConsultorExterno={usuarioConsultorExterno}
                  />
                </div>
              </div>
            )}
            <IosAddSnack
              showInstallMessage={showInstallMessage}
              setShowInstallMessage={setShowInstallMessage}
            />
          </div>
        </ConfirmarEntrarEmContatoModalProvider>
      </ThemeProvider>
    );
  }
);

const AppToolbar = styled(Toolbar)`
  padding-right: ${({ renderchat }) =>
    renderchat === 'true' ? '50px' : '10px'};
`;
