import { isIos } from 'components/utils/BrowserUtils';
import { useUsuarioLogado } from 'hooks/useUsuarioLogado';
import React, { useState } from 'react';
import GooglePlayBtnImg from '../../images/android.png';
import AppleStoreBtnImg from '../../images/apple.png';
import NewAppBannerImg from '../../images/new-app-banner.png';
import { keycloak } from '../../core/offline/kcOfflineStub';
import {
  CloseButtonStyles,
  NewAppBannerDivStyles,
  NewAppBannerStyles,
  StoreButtonStyles,
  NewAppBannerImgStyles,
  HiddenStyles,
  ImageButtonStyles,
} from './styles';

const NEW_APP_BANNER_STORAGE_KEY = 'new-app-banner-shown';

const useRestrictedCustomers = () => {
  const { marcas, usuarioConsultorExterno } = useUsuarioLogado();
  const isAllowed = (marcas || []).find(marca =>
    [
      'jdrede',
      'man-dp',
      'newhollandagriculture',
      'daf',
      'nissan',
      'mercedes',
    ].includes(keycloak.realm.toLowerCase())
  );

  return {
    isAllowed: isAllowed && !usuarioConsultorExterno,
  };
};

export const NewAppBanner = () => {
  const { isAllowed } = useRestrictedCustomers();
  const googlePlayUrl =
    'https://play.google.com/store/apps/details?id=br.com.tecsinapse.dynamo.vendas';
  const appleStoreUrl =
    'https://apps.apple.com/br/app/dynamo-vendas/id1585197158?l=en';
  const bannerShown = localStorage.getItem(NEW_APP_BANNER_STORAGE_KEY);
  const [, setShowBanner] = useState(bannerShown);
  const btnSrc = isIos() ? AppleStoreBtnImg : GooglePlayBtnImg;

  const handleCloseBanner = () => {
    localStorage.setItem(NEW_APP_BANNER_STORAGE_KEY, true);
    setShowBanner(false);
  };

  const handleGoToStore = () => {
    handleCloseBanner();
    window.location = isIos() ? appleStoreUrl : googlePlayUrl;
  };

  if (!isAllowed || bannerShown) {
    return null;
  }

  return (
    <div style={NewAppBannerStyles}>
      <div style={NewAppBannerDivStyles}>
        <img alt="banner" src={NewAppBannerImg} style={NewAppBannerImgStyles} />
        <button
          type="button"
          style={CloseButtonStyles}
          onClick={handleCloseBanner}
        >
          <span style={HiddenStyles}>Close</span>
        </button>
      </div>
      <button type="button" style={StoreButtonStyles} onClick={handleGoToStore}>
        <img
          alt="store button"
          src={btnSrc}
          width="40%"
          style={ImageButtonStyles}
        />
      </button>
    </div>
  );
};
