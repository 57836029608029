/* eslint no-console: 0 */
import { extendMoment } from 'moment-range';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { PersistGate } from 'redux-persist/integration/react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { toIdValue } from 'apollo-utilities';
import { persistCache } from 'apollo-cache-persist';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import axios from 'axios';
import 'font-awesome/css/font-awesome.css';
import localforage from 'localforage';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { Provider } from 'react-redux';
import React, { useDebugValue, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { TunnelProvider } from 'react-tunnels';
import 'react-virtualized/styles.css';
import { createStore } from 'redux';
import { ApolloProvider } from '@apollo/react-hooks';
import 'typeface-roboto';
import { NotificadorProvider } from 'components/Notificacoes';
import { ChatProvider } from 'components/Chat/ChatStore';
import { Chat } from './components/Chat';
import { AppContainer } from './components/app/AppContainer';
import './index.scss';
import appState from './reducers/index';
import registerServiceWorker from './registerServiceWorker';
import { keycloak } from './core/offline/kcOfflineStub';
import { renderOfflineMutationUI } from './core/offline/offlineHelpers/offlineMutationHelpers';
import {
  cacheQueryWithOutId,
  queryCascadeCaching,
} from './core/offline/offlineHelpers/offlineNavegationHelpers';
import { AppThemeProvider } from './core/AppTheme';
import { logout } from './core/logout';
import { reactGoogleTranslatorCrashFix } from './components/utils/reactGoogleTranslatorCrashFix';
import {
  hideOfflineBadge,
  showOfflineBadge,
} from './reducers/offlineBadgeReducer';
import { resetWeekDays } from './reducers/weekDaysReducer';
import UsuarioLogadoProvider from './components/UsuarioLogado/UsuarioLogadoContext';
import { AtualizaModeloInativoProvider } from './components/AtualizaModeloInativo/AtualizaModeloInativoProvider';
import { SelecaoModeloInteresseProvider } from './components/SelecaoModeloInteresse/SelecaoModeloInteresseProvider';
import { ShareBottomSheetProvider } from './components/providers/ShareBottomSheet/ShareBottomSheetProvider';
import { linkOnError } from './core/apolloLinks';
import { GlobalCss } from './core/GlobalCss';

const { Profiler } = React;

ReactGA.initialize(process.env.REACT_APP_GA_UUID, {
  testMode: process.env.NODE_ENV === 'test',
});

const history = createBrowserHistory();

export const appHistory = history;

const moment = extendMoment(Moment);

const persistConfig = {
  key: 'root',
  blacklist: [
    'form',
    'alert',
    'createOpportunityModal',
    'loadingSpinner',
    'home',
    'offlineBadge',
    'clienteDuplicadoDialog',
    'registroAcesso',
  ],
  storage,
};

moment.locale('pt-br');
reactGoogleTranslatorCrashFix();

export const store = createStore(
  persistReducer(persistConfig, appState),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);

// need to wrap the KC "promise object" into a real Promise object
const refreshKeycloakToken = (minValidity = 5) =>
  new Promise((resolve, reject) => {
    if (navigator.onLine) {
      keycloak
        .updateToken(minValidity)
        .then(() => {
          localforage.setItem('subject', keycloak.subject);
          localforage.setItem('token', keycloak.token);
          localforage.setItem('refreshToken', keycloak.refreshToken);
          resolve();
        })
        .catch(error => reject(error));
    } else {
      resolve();
    }
  });

axios.interceptors.request.use(config =>
  refreshKeycloakToken()
    .then(() => {
      const newConfig = config;

      newConfig.headers.Authorization = `Bearer ${keycloak.token}`;

      return Promise.resolve(newConfig);
    })
    .catch(() => {
      keycloak.login({ prompt: 'none' });
    })
);

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      atividade: (_, args) =>
        toIdValue(
          cache.config.dataIdFromObject({
            __typename: 'Atividade',
            id: args.id,
          })
        ),
      cliente: (_, args) =>
        toIdValue(
          cache.config.dataIdFromObject({
            __typename: 'Cliente',
            id: parseInt(args.id, 10),
          })
        ),
      oportunidade: (_, args) =>
        toIdValue(
          cache.config.dataIdFromObject({
            __typename: 'Oportunidade',
            id: args.id,
          })
        ),
      prospeccao: (_, args) =>
        toIdValue(
          cache.config.dataIdFromObject({
            __typename: 'Prospeccao',
            id: args.id,
          })
        ),
    },
  },
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true, // needed for networkStatus
  },
};

persistCache({
  cache,
  storage: localforage,
  maxSize: 1048576 * 20, // 20MB
});

const authMiddleware = setContext((operation, { headers }) =>
  refreshKeycloakToken()
    .then(() => ({
      headers: {
        ...headers,
        authorization: `Bearer ${keycloak.token}`,
      },
    }))
    .catch(() => {
      keycloak.login({ prompt: 'none' });
    })
);

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

export const client = new ApolloClient({
  link: ApolloLink.from([linkOnError, authMiddleware, httpLink]),
  cache,
  connectToDevTools: true,
  defaultOptions,
});

export const onCacheWrite = ({ cacheInput, apolloClient }) => {
  const { write } = cache;
  // eslint-disable-next-line
  cacheInput.write = (...args) => {
    write.apply(cache, args);
    queryCascadeCaching({ args, apolloClient });
  };

  return () => {
    cache.write = write;
  };
};

onCacheWrite({ cacheInput: cache, apolloClient: client });

const onOnlineListenner = () => {
  store.dispatch(hideOfflineBadge());

  return renderOfflineMutationUI({ client });
};

const onOfflineListenner = () =>
  store.dispatch(
    showOfflineBadge({
      lastUpdated: moment().toISOString(),
    })
  );

window.addEventListener('offline', onOfflineListenner);
window.addEventListener('online', onOnlineListenner);

localforage
  .ready()
  .then(() =>
    Promise.all([
      localforage.getItem('token'),
      localforage.getItem('refreshToken'),
    ])
  )
  .then(([token, refreshToken]) => {
    const initOptions = {
      checkLoginIframe: false,
    };

    if (token !== null) {
      initOptions.token = token;
    }

    if (refreshToken !== null) {
      initOptions.refreshToken = refreshToken;
    }

    const afterKcInit = authenticated => {
      if (!authenticated && navigator.onLine) {
        keycloak.login({
          scope: 'offline_access',
        });
      } else {
        localforage.setItem('subject', keycloak.subject);
        localforage.setItem('token', keycloak.token);
        localforage.setItem('refreshToken', keycloak.refreshToken);

        keycloak.loadUserProfile().then(() => {
          store.getState().keycloak = keycloak;
          const requiredRoles = (process.env.REACT_APP_MODULOS || '')
            .split(',')
            .filter(val => val);
          const allowedEmails = (process.env.REACT_APP_EMAILS_PERMITIDOS || '')
            .split(',')
            .filter(val => val);
          let hasPermission =
            process.env.REACT_APP_KC_ENV_TYPE === 'staging' ||
            requiredRoles.reduce(
              (permission, role) =>
                permission && keycloak.hasResourceRole(role),
              true
            );
          const allowedEmail =
            allowedEmails.length === 0 ||
            allowedEmails.indexOf(keycloak.profile.email) > -1;

          hasPermission = hasPermission && allowedEmail;
          ReactDOM.render(
            <EnhancedApp hasPermission={hasPermission} />,
            document.getElementById('root')
          );
        });
      }
    };

    keycloak
      .init(initOptions)
      .then(afterKcInit)
      .catch(() => {
        if (!navigator.onLine) {
          afterKcInit(false);
        } else {
          logout(keycloak, client);
        }
      });
  });

const EnhancedApp = props => {
  useEffect(() => {
    store.dispatch(resetWeekDays());
    cacheQueryWithOutId({ apolloClient: client });

    if (navigator.onLine) {
      onOnlineListenner();
    } else {
      onOfflineListenner();
    }
  }, []);

  useDebugValue(navigator.onLine ? 'Online' : 'Offline');

  return (
    <Profiler id="application" onRender={() => {}}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloProvider client={client}>
            <UsuarioLogadoProvider>
              <NotificadorProvider>
                <AppThemeProvider>
                  <GlobalCss />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ChatProvider>
                      <TunnelProvider>
                        <Router history={appHistory}>
                          <SelecaoModeloInteresseProvider>
                            <AtualizaModeloInativoProvider>
                              <ShareBottomSheetProvider>
                                <AppContainer
                                  {...props}
                                  versao={process.env.REACT_APP_VERSION}
                                />
                              </ShareBottomSheetProvider>
                            </AtualizaModeloInativoProvider>
                          </SelecaoModeloInteresseProvider>
                          <Chat />
                        </Router>
                      </TunnelProvider>
                    </ChatProvider>
                  </MuiPickersUtilsProvider>
                </AppThemeProvider>
              </NotificadorProvider>
            </UsuarioLogadoProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </Profiler>
  );
};

registerServiceWorker();
