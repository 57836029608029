import React, { useEffect, useState } from 'react';
import localforage from 'localforage';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import { Button, IconButton } from '@tecsinapse/ui-kit';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { Sync } from '@material-ui/icons';
import { Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';

import {
  eventSyncChanges,
  eventTickCheckboxForSync,
  eventUntickCheckboxForSync,
} from '../GAUtils';
import { defaultRed } from '../../app/globalStyle';
import { CheckboxList } from '../ui/CheckboxList';

function OfflineSyncDialogComponent({
  onConfirm,
  onCancel,
  online,
  lastUpdateTime,
  classes,
}) {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(options.map(({ id }) => id));
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localforage.getItem('offlineMutations').then(offlineMutations => {
      const newOptions = offlineMutations.map((mutation, index) => ({
        id: index,
        ...mutation,
      }));

      setOptions(newOptions);
      setSelected(newOptions.map(({ id }) => id));
    });
  }, []);

  const handleToggle = value => () =>
    setSelected(previousSelected => {
      const currentIndex = previousSelected.indexOf(value);
      const newChecked = [...previousSelected];

      if (currentIndex === -1) {
        newChecked.push(value);
        eventTickCheckboxForSync();
      } else {
        newChecked.splice(currentIndex, 1);
        eventUntickCheckboxForSync();
      }

      return newChecked;
    });

  const handleClose = () => {
    setShow(false);

    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    eventSyncChanges();
    setLoading(true);
    // construct array of options based on selected ids
    onConfirm(
      selected.map(id => options.find(({ id: optionId }) => optionId === id))
    )
      .then(newMutations => {
        if (newMutations.length === 0) {
          setShow(false);

          return;
        }

        const newOptions = newMutations.map((mutation, index) => ({
          id: index,
          ...mutation,
        }));

        setOptions(newOptions);

        setSelected(newOptions.map(({ id }) => id));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const textoDialogContent = online ? (
    <>
      Desmarque as ações que não gostaria de sincronizar.
      <br />
      <br />
      Ações que não foram sincronizadas com sucesso mais de uma vez serão
      listadas com alerta.
      <br />
      <br />
      Caso haja alguma modificação conflitante, serão tomadas como verdade as
      alterações selecionadas, listadas abaixo:
    </>
  ) : (
    'As seguintes ações não foram realizadas'
  );

  const optionSecundaryIconFunc = m =>
    m.failed && (
      <ListItemSecondaryAction>
        <IconButton aria-label="Alerta">
          <Icon path={mdiAlert} size={1} color={defaultRed} />
        </IconButton>
      </ListItemSecondaryAction>
    );

  return (
    <>
      {loading && <div id="cover-spin" />}
      <Dialog open={show} onClose={handleClose} fullScreen>
        <DialogTitle id="alert-dialog-title">
          {online ? 'Deseja sincronizar suas ações offline?' : 'Offline'}
        </DialogTitle>

        <DialogContent>
          <Typography variant="subtitle2" className={classes.captionTextSync}>
            Atualizado parcialmente pela última vez em{' '}
            {moment(lastUpdateTime).format('hh:mm:ss DD/MM/YYYY')}
          </Typography>

          <Typography variant="caption" className={classes.captionTextSync}>
            {options.length > 0 && textoDialogContent}
          </Typography>

          {options.length > 0 && (
            <>
              <CheckboxList
                renderCheckbox={online}
                options={options}
                selected={selected}
                handleToggle={handleToggle}
                optionSecundaryIconFunc={optionSecundaryIconFunc}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          {online && options.length > 0 && (
            <Button onClick={handleConfirm} customVariant="success" autoFocus>
              <Sync /> Sincronizar ações
            </Button>
          )}

          {!online && (
            <Button
              onClick={() => setShow(false)}
              customVariant="warning"
              autoFocus
            >
              Voltar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
const styles = theme => ({
  captionTextSync: {
    display: 'inline-block',
    marginTop: theme.spacing(1),
  },
});

export const OfflineSyncDialog = withStyles(styles)(OfflineSyncDialogComponent);
