import { Client } from '@stomp/stompjs';
import { useCallback, useEffect } from 'react';
import { useNotificadorHook } from './NotificadorHook';
import { TOPICOS } from './topicos/Topicos';

const wsClient = new Client();

export const Notificador = ({ host, endpoint, userId, topicos }) => {
  const {
    registraChegadaNotificacao,
    buscaNotificacoes,
  } = useNotificadorHook();

  const wsConnect = useCallback(() => {
    topicos.forEach(item => {
      const topicoUsuario = `${item.topicoURI}/keycloakId-${userId}`;

      wsClient.subscribe(topicoUsuario, message => {
        const notificacao = JSON.parse(message.body);
        let processarNotificacao = true;

        if (item.onRecebeNotificacao) {
          processarNotificacao = item.onRecebeNotificacao(notificacao);
        }
        processarNotificacao && registraChegadaNotificacao(notificacao);
      });
    });
    // eslint-disable-next-line
  }, [userId, wsClient, topicos, registraChegadaNotificacao]);

  useEffect(() => {
    const wsHost = String(host).replace('http', 'ws');

    wsClient.configure({
      brokerURL: `${wsHost}${endpoint}`,
      onConnect: () => wsConnect(),
      // eslint-disable-next-line
      debug: message => console.log(message),
    });
    wsClient.activate();

    return () => wsClient.deactivate();
  }, [endpoint, host, userId, wsConnect]);

  useEffect(
    () => userId && buscaNotificacoes(userId, Array.from(TOPICOS.keys())),
    [buscaNotificacoes, userId]
  );

  return null;
};
