/* eslint max-classes-per-file: ["error", 50] */

import { Enum } from 'enumify';

import AtividadeRealizadaIcon from '../images/atividade-realizada.png';
import ComentarioEnviadoEmailIcon from '../images/comentario-enviado-email.png';
import ComentarioIcon from '../images/comentario.png';
import AlteracaoStatusIcon from '../images/icon-rotate-right.png';
import AlteracaoManualStatusIcon from '../images/icon-rotate-left.png';
// import EntregaAgendadaIcon from '../images/entrega-agendada.png';
// import EntregaRealizadaIcon from '../images/entrega-realizada.png';
// import FaturamentoRealizadoIcon from '../images/faturamento-realizado.png';
// import LiberacaoEntregaIcon from '../images/liberacao-entrega.png';
// import LigacaoRealizadaIcon from '../images/ligacao-realizada.png';
// import MudancaManualStatusIcon from '../images/mudanca-manual-status.png';
// import NegociacaoAceitaIcon from '../images/negociacao-aceita.png';
// import PesquisaSatisfacaoIcon from '../images/pesquisa-satisfacao.png';
// import RelacionamentoFeitoIcon from '../images/relacionamento-feito.png';
// import TestDrivreIcon from '../images/test-drive.png';

/* eslint max-classes-per-file: ["error", 50] */

export class BottomSheetClickEnum extends Enum {}
export class ClientCreateOptionEnum extends Enum {}
export class OpportunitySelectedTab extends Enum {}
export class TemperaturaNegociacao extends Enum {}
export class ClientSelectedTab extends Enum {}
export class CheckInSelectedTab extends Enum {}
export class TipoAtividade extends Enum {}
export class TipoEntrarEmContato extends Enum {}
export class TipoOrigemAtividade extends Enum {}
export class TipoAcaoInformarContato extends Enum {}
export class TipoItemTimeline extends Enum {}
export class Estados extends Enum {}
export class OpcoesDataProximaAtividade extends Enum {}
export class ResultadoProspeccao extends Enum {}
export class Funcionalidades extends Enum {}
export class CampoModeloInteresse extends Enum {}
export class ActivityActions extends Enum {}
export class TipoModeloInteresse extends Enum {}
export class MontadoraOutras extends Enum {}
export class StatusOportunidade extends Enum {}
export class TipoIndicadorFup48 extends Enum {}
export class TipoIndicadorExcellenceClubCard extends Enum {}
export class TipoIndicadorExcellenceClubVendedorCard extends Enum {}
export class OportunidadeProdutoAgregadoResultado extends Enum {}

export const enumGetNames = e => e.enumValues.map(v => v.name);

TipoIndicadorExcellenceClubCard.initEnum([
  'EXCELLENCE_CLUB',
  'BONUS_PERFORMANCE',
]);

TipoIndicadorExcellenceClubVendedorCard.initEnum(['FUP48', 'TD']);

TipoModeloInteresse.initEnum(['USADO', 'NOVO']);

TipoEntrarEmContato.initEnum({
  ONLINE: { nome: 'Contato Online Ativo' },
  LIGACAO: { nome: 'Ligação Ativa' },
});
BottomSheetClickEnum.initEnum({
  CALL_CLIENT: {},
  TEST_DRIVE: {},
  RESCHEDULE_ACTIVITY: {},
  INFORM_CALL: {},
  EDIT_CLIENT: {},
  EDIT_OPPORTUNITY: {},
  SEE_HISTORY: {},
  CREATE_OPPORTUNITY: {},
  CREATE_PROSPECTION: {},
  GO_TO_ROUTE: {},
  PERFORM_FICHA_VISITA_ACTIVITY: {},
  PERFORM_CHECKIN: {},
});

TipoAcaoInformarContato.initEnum([
  'CRIAR_OPORTUNIDADE',
  'CRIAR_PROSPECCAO',
  'ESCOLHER_TIPO_CRIACAO',
  'PEDIR_FORMA_DE_CONTATO',
  'REALIZAR_ATIVIDADE',
  'CRIAR_ATIVIDADE',
  'CRIAR_FICHA_VISITA',
  'PEDIR_TIPO_CRIAR_ATIVIDADE',
  'CRIAR_ATIVIDADE_PROSPECCAO',
  'CRIAR_ATIVIDADE_OPORTUNIDADE',
]);
Funcionalidades.initEnum([
  'CRM_PARAMETRIZACOES_DEALER_CONFIGURAR_PARAMETROS_PROPOSTA',
]);
ResultadoProspeccao.initEnum([
  'CONSEGUI_CONTATO',
  'NAO_CONSEGUI_CONTATO',
  'INFORMACOES_INVALIDAS',
]);

OpportunitySelectedTab.initEnum([
  'VEICULOS',
  'PAGAMENTOS',
  'PRODUTOS_AGREGADOS',
]);
ClientSelectedTab.initEnum(['CLIENTE', 'FROTA', 'CONTATOS']);
CheckInSelectedTab.initEnum(['REALIZAR_CHECKIN', 'CHECKIN_RELIZADOS']);

TipoAtividade.initEnum([
  'FOLLOW_UP_ENTREGA',
  'AVULSA',
  'FOLLOW_UP',
  'FOLLOW_UP_EMAIL',
  'PRIMEIRO_ATENDIMENTO',
  'PERSEGUICAO',
  'PERSEGUICAO_POS_REALIZACAO',
  'PERSEGUICAO_ATIVIDADE_ATRASADA',
  'PROSPECCAO_VENDA_REALIZADA',
  'PROSPECCAO_FUP_REALIZADO',
  'PROSPECCAO_VENDA_PERDIDA',
  'VENDA_PERDIDA',
  'RESGATE',
  'PROSPECCAO',
  'QUALIFICACAO',
  'DATA_ANIVERSARIO',
  'ATENDIMENTO_WEB',
  'VISITA',
  'PROSPECCAO_ATIVACAO_CLIENTE',
  'ACOMPANHAMENTO_ENRIQUECIMENTO',
  'CAMPANHA_CLIMA',
  'CAMPANHA_CLIMA_POS_DISPARO_COM_CONTATO_BDC',
  'CONTATO_NEGOCIO_CRIACAO_MANUAL',
  'FOLLOW_UP_TEST_DRIVE',
  'FATURAMENTO',
]);

OportunidadeProdutoAgregadoResultado.initEnum(['OFERECIDO', 'VENDIDO']);

TipoItemTimeline.initEnum({
  ATIVIDADE: {
    icon() {
      return AtividadeRealizadaIcon;
    },
  },
  COMENTARIO: {
    icon() {
      return ComentarioIcon;
    },
  },
  COMENTARIO_RESTRITO: {
    icon() {
      return ComentarioIcon;
    },
  },
  EMAIL_ENVIADO: {
    icon() {
      return ComentarioEnviadoEmailIcon;
    },
  },
  EMAIL_RECEBIDO: {
    icon() {
      return ComentarioEnviadoEmailIcon;
    },
  },
  STATUS: {
    icon() {
      return AlteracaoStatusIcon;
    },
  },
  STATUS_MANUAL: {
    icon() {
      return AlteracaoManualStatusIcon;
    },
  },
});

Estados.initEnum({
  AC: { nome: 'Acre', sigla: 'AC' },
  AL: { nome: 'Alagoas', sigla: 'AL' },
  AM: { nome: 'Amazonas', sigla: 'AM' },
  AP: { nome: 'Amapá', sigla: 'AP' },
  BA: { nome: 'Bahia', sigla: 'BA' },
  CE: { nome: 'Ceará', sigla: 'CE' },
  DF: { nome: 'Distrito Federal', sigla: 'DF' },
  ES: { nome: 'Espírito Santo', sigla: 'ES' },
  GO: { nome: 'Goiás', sigla: 'GO' },
  MA: { nome: 'Maranhão', sigla: 'MA' },
  MG: { nome: 'Minas Gerais', sigla: 'MG' },
  MS: { nome: 'Mato Grosso do Sul', sigla: 'MS' },
  MT: { nome: 'Mato Grosso', sigla: 'MT' },
  PB: { nome: 'Paraíba', sigla: 'PB' },
  PA: { nome: 'Pará', sigla: 'PA' },
  PE: { nome: 'Pernambuco', sigla: 'PE' },
  PI: { nome: 'Piauí', sigla: 'PI' },
  PR: { nome: 'Paraná', sigla: 'PR' },
  RJ: { nome: 'Rio de Janeiro', sigla: 'RJ' },
  RN: { nome: 'Rio Grande do Norte', sigla: 'RN' },
  RO: { nome: 'Rondônia', sigla: 'RO' },
  RR: { nome: 'Roraima', sigla: 'RR' },
  RS: { nome: 'Rio Grande do Sul', sigla: 'RS' },
  SC: { nome: 'Santa Catarina', sigla: 'SC' },
  SE: { nome: 'Sergipe', sigla: 'SE' },
  SP: { nome: 'São Paulo', sigla: 'SP' },
  TO: { nome: 'Tocantins', sigla: 'TO' },
});

OpcoesDataProximaAtividade.initEnum([
  'SETE_DIAS',
  'QUATORZE_DIAS',
  'VINTE_E_OITO_DIAS',
  'CALCULADA_PELA_SISTEMATICA',
  'DEFINIR_DATA',
]);

CampoModeloInteresse.initEnum(['COR', 'COR_INTERIOR']);

ActivityActions.initEnum([
  'GO_TO_LOST_BUSINESS',
  'GO_TO_PERFORM_VISIT_ACTIVITY',
]);

ClientCreateOptionEnum.initEnum({
  OPPORTUNITY: { title: 'Nova oportunidade' },
  PROSPECTION: { title: 'Nova prospecção' },
  INFORM_CALL: { title: 'Informar Contato' },
  CHOICE: { title: 'Informar Contato' },
  PEDIR_TIPO_CRIAR_ATIVIDADE: { title: 'Criar Atividade' },
  NOVA_ATIVIDADE_FICHA_VISITA: { title: 'Atividade' },
  GERAR_ROTEIRIZACAO: { title: 'Gerar Roteirização' },
});

TemperaturaNegociacao.initEnum({
  NAO_INFORMADA: { nome: 'Não informada' },
  SUPER_QUENTE: { nome: 'Super Quente' },
  QUENTE: { nome: 'Quente' },
  MORNO: { nome: 'Morno' },
  FRIO: { nome: 'Frio' },
  BOLSAO: { nome: 'Bolsão' },
});

MontadoraOutras.initEnum([
  'BMW',
  'BMW_MOTORRAD',
  'MINI',
  'VOLKSWAGEN',
  'HONDA',
  'CITROEN',
  'VOLVO',
  'MERCEDES',
  'SMART',
  'JEEP',
  'CHRYSLER',
  'DODGE',
  'RAM',
  'KIA',
  'AUDI',
  'MERCEDES_COMERCIAIS',
  'NISSAN',
  'HARLEY',
  'TRIUMPH',
  'DUCATI',
  'JAGUAR',
  'LAND_ROVER',
  'CHEVROLET',
  'PEUGEOT',
  'TOYOTA',
  'FORD',
  'SCANIA',
  'IVECO',
  'BRP',
  'MAN',
  'VWCO',
  'MF',
  'OUTRAS_MARCAS',
  'NEWHOLLAND_AGRICULTURE',
]);

StatusOportunidade.initEnum({
  NOVO: { nome: 'Novo' },
  EM_NEGOCIACAO: { nome: 'Em Negociação' },
  NEGOCIACAO_ACEITA: { nome: 'Negociação Aceita' },
  VENDA_REALIZADA: { nome: 'Vendido' },
  VENDA_PERDIDA: { nome: 'Venda Perdida' },
});

TipoOrigemAtividade.initEnum({
  PROSPECCAO: {
    descricao: namePattern => namePattern,
  },
  OPORTUNIDADE: { descricao: () => 'Oportunidade' },
  FICHA_VISITA: { descricao: () => 'Ficha de Visita' },
});

TipoIndicadorFup48.initEnum({
  HOJE: {},
  PROXIMO_DIA_UTIL: {},
  DEMAIS_DIAS: {},
});
