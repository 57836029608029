import React from 'react';
import { Query } from '@apollo/react-components';
import { Loading } from '../utils/Loading';
import { USUARIO_LOGADO_QUERY_GQL } from './data/USUARIO_LOGADO_QUERY_GQL';

const DEFAULT_STATE = {};

export const UsuarioLogadoContext = React.createContext(DEFAULT_STATE);

export const getUsuarioLogado = (data, modulo) => {
  return {
    ...data?.usuarioLogado,
    consultorExterno:
      (modulo || '').split(',').find(val => val === 'CONSULTOR_EXTERNO') &&
      data?.usuarioLogado?.consultorExterno,
  };
};

export default function UsuarioLogadoProvider(props) {
  return (
    <Query query={USUARIO_LOGADO_QUERY_GQL} fetchPolicy="cache-first">
      {({ loading, data }) => {
        if (loading) {
          return <Loading />;
        }

        const usuarioLogado = getUsuarioLogado(
          data,
          process.env.REACT_APP_MODULOS
        );

        return (
          <UsuarioLogadoContext.Provider
            value={{
              usuarioLogado,
              marcas: usuarioLogado?.marcas,
              usuarioGerente: usuarioLogado?.gerente,
              usuarioAdmin: usuarioLogado?.administrador,
              usuarioVendedor:
                usuarioLogado?.vendedorCrm &&
                !(usuarioLogado.gerente || usuarioLogado.administrador),
              usuarioConsultorExterno: usuarioLogado?.consultorExterno,
              exibeIndicadores: !usuarioLogado?.consultorExterno,
              usuarioInterno: usuarioLogado?.email?.includes(
                '@tecsinapse.com.br'
              ),
              exibeRoteirizacao: usuarioLogado && usuarioLogado.roteirizacaoV2,
            }}
          >
            {props.children}
          </UsuarioLogadoContext.Provider>
        );
      }}
    </Query>
  );
}
