import { useLazyQuery, useMutation } from '@apollo/react-hoc';
import { getLocalDateString } from 'components/utils/dateUtils';
import gql from 'graphql-tag';
import { useUsuarioLogado } from 'hooks/useUsuarioLogado';
import omit from 'lodash.omit';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

const ADICIONA_PRODUTO_AGREGADO = gql(`
    mutation AdicionaProdutoAgregado($produto: OportunidadeProdutoAgregadoInput!){
      adicionaProdutoAgregado(produto: $produto) {
        id
      }
    }
`);

const BUSCA_PRODUTO_AGREGADOS = gql(`
    query BuscaProdutosAgregados($dealerId: ID!){
      buscaProdutosAgregados(dealerId: $dealerId) {
        id
	      nome
      }
    }
`);

export const useProdutoAgregadoEditor = ({
  match: {
    params: { idOportunidade },
  },
  showAlert,
}) => {
  const history = useHistory();
  const { location: { state } = {} } = history;
  const [produtoSelecionado, setProdutoSelecionado] = useState({
    dealer: state?.idDealer,
  });
  const usuarioHook = useUsuarioLogado();
  const [adicionaProdutoAgregadoGQL] = useMutation(ADICIONA_PRODUTO_AGREGADO);
  const [buscaProdutosAgregadosGQL, produtosData] = useLazyQuery(
    BUSCA_PRODUTO_AGREGADOS
  );
  const dealers = usuarioHook.usuarioLogado.dealersVendedorLogado || [];
  const apenasUmDealer = dealers.length === 1;

  const validaDados = useCallback(({ dataInicioVigencia, dataFimVigencia }) => {
    if (
      dataInicioVigencia &&
      dataFimVigencia &&
      dataInicioVigencia.isAfter(dataFimVigencia)
    ) {
      throw Error(
        'Data inicial de vigência deve ser menor que a data final de vigência.'
      );
    }
  }, []);

  const handleAdicionaProduto = useCallback(
    async dadosFormulario => {
      try {
        validaDados(dadosFormulario);

        const produtoInput = {
          ...omit(dadosFormulario, ['dealer']),
          oportunidade: idOportunidade,
          dataInicioVigencia:
            dadosFormulario.dataInicioVigencia &&
            getLocalDateString(dadosFormulario.dataInicioVigencia),
          dataFimVigencia:
            dadosFormulario.dataFimVigencia &&
            getLocalDateString(dadosFormulario.dataFimVigencia),
        };

        await adicionaProdutoAgregadoGQL({
          variables: {
            produto: produtoInput,
          },
        });

        history.goBack();
        showAlert({
          message: 'Produto associado com sucesso!',
          variant: 'success',
          chip: true,
        });
      } catch (e) {
        showAlert({
          message: e.message,
          variant: 'error',
        });
      }
    },
    [
      adicionaProdutoAgregadoGQL,
      history,
      idOportunidade,
      showAlert,
      validaDados,
    ]
  );

  const handleAlteracaoResultado = useCallback(resultadoInput => {
    setProdutoSelecionado(produtoState => ({
      ...produtoState,
      resultado: resultadoInput,
    }));
  }, []);

  const handleAlteracaoDealer = useCallback(dealerInput => {
    setProdutoSelecionado(produtoState => ({
      ...produtoState,
      dealer: dealerInput,
    }));
  }, []);

  useEffect(() => {
    buscaProdutosAgregadosGQL({
      variables: {
        dealerId: produtoSelecionado.dealer,
      },
    });
  }, [produtoSelecionado.dealer, buscaProdutosAgregadosGQL]);

  useEffect(() => {
    if (apenasUmDealer) {
      handleAlteracaoDealer(dealers[0].id);
    }
  }, [dealers, apenasUmDealer, handleAlteracaoDealer]);

  return {
    produtoSelecionado,
    produtos: produtosData?.data?.buscaProdutosAgregados || [],
    dealers,
    apenasUmDealer,
    handleAdicionaProduto,
    handleAlteracaoResultado,
    handleAlteracaoDealer,
  };
};
