export default {
  bmw: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'BMW',
      clientId: 'BMWGestaoDealer',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'BMW',
      clientId: 'BMWGestaoDealer',
    },
  },
  mercedes: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'Mercedes',
      clientId: 'MercedesGestaoDealer',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'Mercedes',
      clientId: 'MercedesGestaoDealer',
    },
  },
  nissan: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'Nissan',
      clientId: 'NissanSeminovos',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'Nissan',
      clientId: 'NissanSeminovos',
    },
  },
  vwco: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'Man-DP',
      clientId: 'VWCODynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'Man-DP',
      clientId: 'VWCODynamoVendas',
    },
  },
  mf: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'MF',
      clientId: 'MFDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'MF',
      clientId: 'MFDynamoVendas',
    },
  },
  michelin: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'PortalMic',
      clientId: 'MicGestaoDealer',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'PortalMic',
      clientId: 'MicGestaoDealer',
    },
  },
  bridgestone: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'Bridgestone',
      clientId: 'BridgestoneConsultorExterno',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'Bridgestone',
      clientId: 'BridgestoneConsultorExterno',
    },
  },
  multimarcas: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'Multimarcas',
      clientId: 'MultimarcasDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'Multimarcas',
      clientId: 'MultimarcasDynamoVendas',
    },
  },
  fiat: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'Fiat',
      clientId: 'FiatDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'Fiat',
      clientId: 'FiatDynamoVendas',
    },
  },
  chevrolet: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'Chevrolet',
      clientId: 'ChevroletDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'Chevrolet',
      clientId: 'ChevroletDynamoVendas',
    },
  },
  daf: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'DAF',
      clientId: 'DAFDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'DAF',
      clientId: 'DAFDynamoVendas',
    },
  },
  audi: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'AudiRede',
      clientId: 'AudiRedeDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'AudiRede',
      clientId: 'AudiRedeDynamoVendas',
    },
  },
  newhollandagriculture: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'NewHollandAgriculture',
      clientId: 'NewHollandAgricultureDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'NewHollandAgriculture',
      clientId: 'NewHollandAgricultureDynamoVendas',
    },
  },
  jdrede: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'JDRede',
      clientId: 'JDRedeDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'JDRede',
      clientId: 'JDRedeDynamoVendas',
    },
  },
  jdrede_consultorexterno: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'JDRede',
      clientId: 'JDRedeDynamoVendas',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'JDRede',
      clientId: 'JDRedeDynamoVendas',
    },
  },
  multimarcas_pneus: {
    staging: {
      url: 'https://authhomolog.portaltecsinapse.com.br/',
      realm: 'MultimarcasPneus',
      clientId: 'MultimarcasPneusCE',
    },
    prod: {
      url: 'https://auth.portaltecsinapse.com.br/',
      realm: 'MultimarcasPneus',
      clientId: 'MultimarcasPneusCE',
    },
  },
};
