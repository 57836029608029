import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Typography from '@material-ui/core/Typography/Typography';
import ReactGA from 'react-ga';
import { CheckInCEConfirmacaoContainer } from 'components/CheckIn/ui/CheckInCEConfirmacaoContainer';
import { CheckInCRMConfirmacaoContainer } from 'components/CheckIn/ui/CheckInCRMConfirmacaoContainer';
import { ProdutoAgregadoEditor } from 'components/opportunity/ProdutoAgrupado';
import { ClienteContainer } from '../Client/client/ui/ClienteContainer';
import { ClientContactsEditorContainer } from '../Client/clienteContacts/ui/ClientContactsEditorContainer';
import { ClienteSearchContainer } from '../Client/client/ui/ClienteSearchContainer';
import { ClienteFrotaEditorContainer } from '../Client/clienteFrota/ui/ClienteFrotaEditorContainer';
import { AgendaContainer } from '../Home/AgendaContainer';
import { OpportunityVehiclesEditorContainer } from '../opportunity/editor/OpportunityVehiclesEditorContainer';
import { OpportunityVehiclesPerformTestDriveContainer } from '../opportunity/editor/OpportunityVehiclesPerformTestDriveContainer';
import { OpportunityContainer } from '../opportunity/OpportunityContainer';
import { ProposalPaymentEditorContainer } from '../proposal/payment/editor/ProposalPaymentEditorContainer';
import { AtividadeCompletadaEDataProximaContainer } from '../RealizarAtividade/AtividadeCompletadaEDataProxima';
import { RealizarAtividadeContainer } from '../RealizarAtividade/RealizarAtividadeContainer';
import { AtividadeTimelineContainer } from '../timeline/AtividadeTimelineContainer';
import { AddCommentEditorContainer } from '../timeline/comment/AddCommentEditorContainer';
import { resetAppTitle } from '../utils/uiUtils';
import { SettingsContainer } from '../Settings/SettingsContainer';
import { IndicadoresContainer } from '../Indicadores/ui/IndicadoresContainer';
import { RotasContainer } from '../Rotas/ui/RotasContainer';
import { ErrorBoundary } from '../utils/Errors/ErrorBoundary';
import { MapaComponent } from '../Rotas/ui/MapaComponent';
import { Admin } from '../Admin/Admin';
import { Notificacoes } from '../Notificacoes';
import { CheckInContainer } from '../CheckIn/ui/CheckInContainer';
import { ROUTE_CHECK_IN } from '../../constantes/constantes';
import { MenuContainer } from '../Menu';

const style = { margin: '50px auto' };
const RouteNotFound = () => (
  <>
    {resetAppTitle()}
    <Typography variant="body2" color="inherit" style={style}>
      Ops, não tem nada aqui!
    </Typography>
  </>
);

const fireTracking = () => {
  ReactGA.pageview(window.location.pathname);
  ReactGA.ga('set', 'appVersion', process.env.REACT_APP_VERSION);
};

export const Routes = ({ usuarioLogado }) => (
  <ErrorBoundary>
    <Switch>
      <Redirect exact from="/" to="/home" onUpdate={fireTracking} />
      <Redirect
        exact
        from="/agenda/reload"
        to="/agenda"
        onUpdate={fireTracking}
      />
      <Route
        path="/agenda"
        component={AgendaContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/settings"
        component={SettingsContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/clientes"
        component={ClienteSearchContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/notificacoes"
        component={Notificacoes}
        onUpdate={fireTracking}
      />
      <Route path="/home" component={MenuContainer} onUpdate={fireTracking} />
      <Route
        path="/indicadores/:urlRetorno"
        component={IndicadoresContainer}
        onUpdate={fireTracking}
        exact
        showBack
      />
      <Route
        path="/indicadores"
        component={IndicadoresContainer}
        onUpdate={fireTracking}
      />
      <Route
        path={ROUTE_CHECK_IN}
        component={CheckInContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/checkInCRMConfirmacao/:idCliente"
        component={CheckInCRMConfirmacaoContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/checkInConfirmacao/:idCliente"
        component={CheckInCEConfirmacaoContainer}
        onUpdate={fireTracking}
      />
      <Route path="/rotas" component={RotasContainer} onUpdate={fireTracking} />
      <Route
        path="/editarCliente/:id?"
        component={ClienteContainer}
        showBack
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/editarClienteChat/:data?"
        component={props => (
          <ClienteContainer {...props} key={window.location.pathname} />
        )}
        showBack
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/editarCliente/:id?/:urlRetorno"
        component={ClienteContainer}
        showBack
        onUpdate={fireTracking}
        exact
      />
      <Route
        path="/editarContatoCliente/:idCliente/:id?"
        component={ClientContactsEditorContainer}
        showBack
        onUpdate={fireTracking}
      />
      <Route
        path="/editarVeiculoCliente/:idCliente/:idItem?"
        component={ClienteFrotaEditorContainer}
        showBack
        onUpdate={fireTracking}
      />
      <Route
        path="/realizarAtividade/:id/:idContato/:tipoContato"
        component={RealizarAtividadeContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/realizarAtividade/:id/:idContato/:tipoContato/:urlRetorno"
        component={RealizarAtividadeContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/realizarAtividade/:id/:urlRetorno?"
        component={RealizarAtividadeContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/realizarAtividade/:id/:roteirizacaoClienteId/:urlRetorno/rotas"
        component={RealizarAtividadeContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/criarERealizarAtividade/:tipoAcaoInformarContato/:clientId/:unidadeNegocioId"
        component={RealizarAtividadeContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/criarERealizarAtividade/:tipoAcaoInformarContato/:clientId/:unidadeNegocioId/:idContato/:tipoContato"
        component={RealizarAtividadeContainer}
        exact
      />
      <Route
        path="/timeline/:id/"
        component={AtividadeTimelineContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/timeline/cliente/:clienteId/"
        component={AtividadeTimelineContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/timeline/:idOrigemAtividade/:tipoOrigemAtividade/"
        component={AtividadeTimelineContainer}
        exact
        onUpdate={fireTracking}
      />
      <Route
        path="/adicionarComentarioTimeline/:id"
        component={AddCommentEditorContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/oportunidade/:idOportunidade/pagamentos/:idProposta/editar/:id?"
        component={ProposalPaymentEditorContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/oportunidade/:idOportunidade/produtoAgregado"
        component={ProdutoAgregadoEditor}
        onUpdate={fireTracking}
      />
      <Route
        path="/oportunidade/:idOportunidade/editarVeiculoInteresse/:id?"
        component={OpportunityVehiclesEditorContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/oportunidade/:idOportunidade/realizarTestDrive/:id?"
        component={OpportunityVehiclesPerformTestDriveContainer}
        onUpdate={fireTracking}
      />
      <Route
        path="/oportunidade/:id"
        component={OpportunityContainer}
        onUpdate={fireTracking}
      />
      <Route path="/admin" component={Admin} onUpdate={fireTracking} />
      <Route
        path="/atividade/:id/dataProximaAtividade/"
        component={AtividadeCompletadaEDataProximaContainer}
        onUpdate={fireTracking}
      />
      <Route path="/mapa" component={MapaComponent} onUpdate={fireTracking} />
      <Route component={RouteNotFound} onUpdate={fireTracking} />
    </Switch>
  </ErrorBoundary>
);
